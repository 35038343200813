.topbar {
  background-color: $section-background-color;
  padding: 0 22px;
  margin-left: 70px;

  @include transition(margin-left 120ms linear);
  @include boxshadow(0px 11px 24px -7px $shadow-color);

  &:has(& + .open) {
    margin-left: 260px;
  }

  .brand {
    display: flex;
    align-items: center;

    .logo {
      padding-right: 0.5em;
      display: flex;
    }

    .name {
      color: $topbar-text-color;
      font-family: $poppins-bold;
      font-size: 26px;
    }
  }

  .home-link {
    text-decoration: none;
  }
}
