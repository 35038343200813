#preloader {
  div {
    width: 100%;
    height: 100%;
    top: 0;
    position: fixed;
    z-index: 99999;
    background: $main-background-color;
    .cssload-speeding-wheel {
      position: absolute;
      top: calc(50% - 3.5px);
      left: calc(50% - 3.5px);
    }

    .loader,
    .loader__figure {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      -o-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
    }

    .loader {
      overflow: visible;
      padding-top: 2em;
      height: 0;
      width: 2em;

      .loader__figure {
        height: 0;
        width: 0;
        box-sizing: border-box;
        border: 0 solid $preloader-color;
        border-radius: 50%;
        -webkit-animation: loader-figure 1.15s infinite
          cubic-bezier(0.215, 0.61, 0.355, 1);
        -moz-animation: loader-figure 1.15s infinite
          cubic-bezier(0.215, 0.61, 0.355, 1);
        animation: loader-figure 1.15s infinite
          cubic-bezier(0.215, 0.61, 0.355, 1);
      }

      .loader__label {
        font-family: $montserrat-regular;
        float: left;
        -webkit-transform: translateX(-50%);
        -moz-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        -o-transform: translateX(-50%);
        transform: translateX(-50%);
        margin: 0.5em 0 0 50%;
        font-size: 0.875em;
        letter-spacing: 0.1em;
        line-height: 1.5em;
        color: $preloader-color;
        white-space: nowrap;
        //-webkit-animation: loader-label 1.15s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
        //-moz-animation: loader-label 1.15s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
        //animation: loader-label 1.15s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
      }
    }
  }
}

@-webkit-keyframes loader-figure {
  0% {
    height: 0;
    width: 0;
    background-color: $preloader-color;
  }
  29% {
    background-color: $preloader-color;
  }
  30% {
    height: 2em;
    width: 2em;
    background-color: transparent;
    border-width: 1em;
    opacity: 1;
  }
  100% {
    height: 2em;
    width: 2em;
    border-width: 0;
    opacity: 0;
    background-color: transparent;
  }
}
@-moz-keyframes loader-figure {
  0% {
    height: 0;
    width: 0;
    background-color: $preloader-color;
  }
  29% {
    background-color: $preloader-color;
  }
  30% {
    height: 2em;
    width: 2em;
    background-color: transparent;
    border-width: 1em;
    opacity: 1;
  }
  100% {
    height: 2em;
    width: 2em;
    border-width: 0;
    opacity: 0;
    background-color: transparent;
  }
}
@keyframes loader-figure {
  0% {
    height: 0;
    width: 0;
    background-color: $preloader-color;
  }
  29% {
    background-color: $preloader-color;
  }
  30% {
    height: 2em;
    width: 2em;
    background-color: transparent;
    border-width: 1em;
    opacity: 1;
  }
  100% {
    height: 2em;
    width: 2em;
    border-width: 0;
    opacity: 0;
    background-color: transparent;
  }
}
@-webkit-keyframes loader-label {
  0% {
    opacity: 0.25;
  }
  30% {
    opacity: 1;
  }
  100% {
    opacity: 0.25;
  }
}
@-moz-keyframes loader-label {
  0% {
    opacity: 0.25;
  }
  30% {
    opacity: 1;
  }
  100% {
    opacity: 0.25;
  }
}
@keyframes loader-label {
  0% {
    opacity: 0.25;
  }
  30% {
    opacity: 1;
  }
  100% {
    opacity: 0.25;
  }
}
