@font-face {
  font-family: "Montserrat Regular";
  src: url("../../fonts/montserrat/Montserrat-Regular.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Montserrat Italic";
  src: url("../../fonts/montserrat/Montserrat-Italic.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Montserrat Black";
  src: url("../../fonts/montserrat/Montserrat-Black.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Montserrat Black Italic";
  src: url("../../fonts/montserrat/Montserrat-BlackItalic.ttf")
    format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Montserrat Bold";
  src: url("../../fonts/montserrat/Montserrat-Bold.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Montserrat Bold Italic";
  src: url("../../fonts/montserrat/Montserrat-BoldItalic.ttf")
    format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Montserrat Extra Bold";
  src: url("../../fonts/montserrat/Montserrat-ExtraBold.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Montserrat Extra Bold Italic";
  src: url("../../fonts/montserrat/Montserrat-ExtraBoldItalic.ttf")
    format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Montserrat Extra Light";
  src: url("../../fonts/montserrat/Montserrat-ExtraLight.ttf")
    format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Montserrat Extra Light Italic";
  src: url("../../fonts/montserrat/Montserrat-ExtraLightItalic.ttf")
    format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Montserrat Light";
  src: url("../../fonts/montserrat/Montserrat-Light.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Montserrat Light Italic";
  src: url("../../fonts/montserrat/Montserrat-LightItalic.ttf")
    format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Montserrat Medium";
  src: url("../../fonts/montserrat/Montserrat-Medium.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Montserrat Medium Italic";
  src: url("../../fonts/montserrat/Montserrat-MediumItalic.ttf")
    format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "Montserrat Semi Bold";
  src: url("../../fonts/montserrat/Montserrat-SemiBold.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Montserrat Semi Bold Italic";
  src: url("../../fonts/montserrat/Montserrat-SemiBoldItalic.ttf")
    format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Montserrat Thin";
  src: url("../../fonts/montserrat/Montserrat-Thin.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Montserrat Thin Italic";
  src: url("../../fonts/montserrat/Montserrat-ThinItalic.ttf")
    format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Poppins Regular";
  src: url("../../fonts/poppins/Poppins-Regular.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Poppins Italic";
  src: url("../../fonts/poppins/Poppins-Italic.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Poppins Black";
  src: url("../../fonts/poppins/Poppins-Black.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Poppins Black Italic";
  src: url("../../fonts/poppins/Poppins-BlackItalic.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Poppins Bold";
  src: url("../../fonts/poppins/Poppins-Bold.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Poppins Bold Italic";
  src: url("../../fonts/poppins/Poppins-BoldItalic.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Poppins Extra Bold";
  src: url("../../fonts/poppins/Poppins-ExtraBold.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Poppins Extra Bold Italic";
  src: url("../../fonts/poppins/Poppins-ExtraBoldItalic.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Poppins Light";
  src: url("../../fonts/poppins/Poppins-Light.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Poppins Light Italic";
  src: url("../../fonts/poppins/Poppins-LightItalic.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Poppins Medium";
  src: url("../../fonts/poppins/Poppins-Medium.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Poppins Medium Italic";
  src: url("../../fonts/poppins/Poppins-MediumItalic.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Poppins Semi Bold";
  src: url("../../fonts/poppins/Poppins-SemiBold.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Poppins Semi Bold Italic";
  src: url("../../fonts/poppins/Poppins-SemiBoldItalic.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Poppins Thin";
  src: url("../../fonts/poppins/Poppins-Thin.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Poppins Thin Italic";
  src: url("../../fonts/poppins/Poppins-ThinItalic.ttf") format("truetype");
  font-weight: normal;
}

/*
@font-face {
  font-family: 'Montserrat Regular';
  src: url('../../fonts/montserrat/Montserrat-Regular.ttf') format('truetype'),
  url('../../fonts/poppins/Poppins-Regular.otf') format('otf'),
  url('../../fonts/poppins/Poppins-Regular.ttf') format('truetype'),
  url('../../fonts/poppins/Poppins-Regular.svg') format('svg');
  font-display: swap;
}
*/

$montserrat-regular: "Montserrat Regular";
$montserrat-italic: "Montserrat Italic";
$montserrat-black: "Montserrat Black";
$montserrat-black-italic: "Montserrat Black Italic";
$montserrat-bold: "Montserrat Bold";
$montserrat-bold-italic: "Montserrat Bold Italic";
$montserrat-extra-bold: "Montserrat Extra Bold";
$montserrat-extra-bold-italic: "Montserrat Extra Bold Italic";
$montserrat-extra-light: "Montserrat Extra Light";
$montserrat-extra-light-italic: "Montserrat Extra Light Italic";
$montserrat-light: "Montserrat Light";
$montserrat-light-italic: "Montserrat Light Italic";
$montserrat-Medium: "Montserrat Medium";
$montserrat-Medium-italic: "Montserrat Medium Italic";
$montserrat-semi-bold: "Montserrat Semi Bold";
$montserrat-semi-bold-italic: "Montserrat Semi Bold Italic";
$montserrat-thin: "Montserrat Thin";
$montserrat-thin-italic: "Montserrat Thin Italic";

$poppins-regular: "Poppins Regular";
$poppins-black: "Poppins Black";
$poppins-black-italic: "Poppins Black Italic";
$poppins-bold: "Poppins Bold";
$poppins-bold-italic: "Poppins Bold Italic";
$poppins-extra-bold: "Poppins Extra Bold";
$poppins-extra-bold-italic: "Poppins Extra Bold Italic";
$poppins-light: "Poppins Light";
$poppins-light-italic: "Poppins Light Italic";
$poppins-medium: "Poppins Medium";
$poppins-medium-italic: "Poppins Medium Italic";
$poppins-semi-bold: "Poppins Semi Bold";
$poppins-semi-bold-italic: "Poppins Semi Bold Italic";
$poppins-thin: "Poppins Thin";
$poppins-thin-italic: "Poppins Thin Italic";

.text-muted {
  font-size: 12px;
  font-family: $montserrat-regular;
}

.text-normal {
  font-size: 12px;
  font-family: $montserrat-regular;
}

.text-small {
  font-size: 11px;
  font-family: $montserrat-regular;
}

.text-big {
  font-size: 14px;
  font-family: $montserrat-semi-bold;
}

.text-extra-big {
  font-size: 24px;
  font-family: $montserrat-semi-bold;
}

.text-extra-light {
  font-weight: 100;
}

.text-light {
  font-weight: 300;
}

.text-bold {
  font-weight: 400;
}

.text-extra-bold {
  font-weight: 700;
}
