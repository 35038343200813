@import "variables/variables";
@import "fonts/fonts";
@import "animations/animations";
@import "mixins/mixins";
@import "components/components";

body {
  background-color: $main-background-color;
}

h1,
h2,
h3,
h4,
h5,
h6,
span,
p,
td,
small {
  font-family: $montserrat-regular;
  color: #212529;
}

th,
th p {
  font-family: $montserrat-semi-bold;
}

p,
.small {
  margin: 5px auto !important;
}

p {
  font-size: 0.9em !important;
}

// Fixes
.table > :not(caption) > * > * {
  padding: 0.512rem 0.5rem !important;
}

.notifier {
  position: absolute;
  background-color: $notifier-color;
  border-radius: 100%;
  width: 10px;
  height: 10px;
  text-align: center;
  font-size: 10px;
  color: #fff;
  top: -7px;
  left: 21px;
  font-family: $poppins-regular;
}

.icon {
  display: block;
  width: 24px;
  height: 24px;
  user-select: none;
}

.icon.sm {
  width: 20px;
  height: 20px;
}

.icon-medium {
  display: block;
  width: 26px;
  height: 26px;
  user-select: none;
}

.icon-big {
  display: block;
  width: 48px;
  height: 48px;
  user-select: none;
}

/*
  Overrides
*/
.card {
  height: 100%;

  .card-title {
    font-family: $poppins-regular;
    font-weight: 200;
    font-size: 18px;
    line-height: 1.4;
  }
}

.dropdown-toggle::after {
  content: none;
}

/*
  To be segmented
*/
.faq-section {
  .faq-title {
    font-family: $poppins-regular;
    font-size: 22px;
    font-weight: bold;
  }
  .faqs {
    list-style: none;

    .faq {
      display: flex;
      line-height: 3;

      .icon {
        padding-right: 0.35em;
      }
    }
  }
}

.search-group {
  position: relative;

  .icon {
    position: absolute;
    right: 15px;
    top: 10px;
  }

  .search {
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    position: relative;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    min-width: 0;
    margin-bottom: 0;
    background: #ffffff 0% 0% no-repeat padding-box;
    opacity: 1;
    padding: 1.45em 1em;
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    color: #67757c;
    min-height: 38px;
    outline: none;
    box-shadow: none;
  }
}

.page-title {
  margin: 0;
}

.heartbeat {
  width: 6px;
  height: 6px;
  border-radius: 30px;
  position: absolute;
  top: 0;
  right: 0;
  background-color: #ff5c6c !important;

  &:after {
    content: "";
    position: absolute;
    top: -7px;
    right: -7px;
    height: 20px;
    width: 20px;
    z-index: 10;
    border: 5px solid #ff5c6c;
    border-radius: 70px;

    @include animation(heartbeat 1s ease-out);
  }
}

.user-profile-dropdown {
  display: flex;

  .icon {
    margin-left: 0.5em;
  }
}

/* @todo move this code to somewhere it belongs */

:root {
  --primary: #1aac83;
  --error: #e7195a;
}

.transparent-btn {
  background: transparent !important;
  border: transparent !important;
  color: inherit !important;
}

div.error {
  padding: 10px;
  background: #ffefef;
  border: 1px solid var(--error);
  color: var(--error);
  border-radius: 4px;
  margin: 20px 0;
}
input.error {
  border: 1px solid var(--error);
}

a {
  color: #000 !important;
  text-decoration: none !important;
}

@media print {
  a {
    color: #000 !important;
    text-decoration: none !important;
  }

  @for $i from 1 through 12 {
    .col-xs-#{$i} {
      width: #{percentage(round($i * 8.33) / 100)};
      float: left;
    }
  }
}
