.footer {
  background-color: $section-background-color;
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
  padding: 0.5em;
  p {
    margin: 0;
  }
}
