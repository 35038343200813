.sidebar {
  top: 0;
  background-color: $section-background-color;
  position: fixed;
  height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 22px;
  padding-right: 22px;

  @include transition(width 100ms linear);
  @include boxshadow(1px 60px 24px -7px $shadow-color);

  &.close {
    width: 70px;

    .nav-item {
      .nav-content {
        .text {
          opacity: 0;
          padding: 0 !important;
          width: 0;
        }
      }
    }
  }

  &.open {
    width: 260px;

    .nav-item {
      .nav-content {
        .text {
          @include transition(opacity 350ms linear);
        }
      }
    }

    & + .topbar,
    & + .topbar + .content {
      margin-left: 260px;
    }
  }

  .menu {
    list-style: none;
    padding: 0;
    width: 100%;

    .nav-item {
      padding: 1.25em 0;
      cursor: pointer;

      .nav-content {
        display: flex;
        align-items: center;
        position: relative;

        .icon {
          height: auto;
          position: absolute;
        }

        .text {
          padding-left: 2.5em;
          font-family: $montserrat-regular;
          color: $sidemenu-text-color;
        }
      }

      a {
        text-decoration: none;
      }

      & :hover .text {
        color: $sidemenu-text-color-hover;
      }

      &:first-child {
        padding: 1.25em 0;
      }

      &:last-child {
        bottom: 2em;
        position: fixed;
      }
    }
  }
}
