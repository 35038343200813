@mixin transition($args...) {
  -webkit-transition: #{$args};
  -moz-transition: #{$args};
  -o-transition: #{$args};
  transition: #{$args};
}

@mixin boxshadow($args...) {
  box-shadow: #{$args};
  -webkit-box-shadow: #{$args};
  -moz-box-shadow: #{$args};
}

@mixin animation($args...) {
  -moz-animation: #{$args};
  -o-animation: #{$args};
  -webkit-animation: #{$args};
  animation: #{$args};
  -moz-animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}
